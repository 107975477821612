@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;900&display=swap");

:root {
  --asada-light-green: rgb(126, 174, 70);
  --asada-lemonade-green: #529b04;
  --asada-dark-green: #139048;
  --asada-dark-blue: rgb(23, 71, 118);
  --asada-light-blue: #79b9d6;
}
html {
  scroll-behavior: smooth;
  font-family: Poppins;
}

.location-icon {
  font-size: 2rem;
  color: red;
}

/* .video-container {
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background: var(--asada-dark-blue) url("./assets/bg_aguapura.jpg") no-repeat
    center center/cover;
} */

/* .video-container video {
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
} */

/* WATER STORAGE TANKS SECTION  */
.storage-tanks-image-container {
  height: calc(100vh / 2);
  width: 100%;
}

.storage-tanks-image-container img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.storage-tanks-info {
  height: 100%;
}

/* QUIEBRAGRADIENTES SECTION */
.quiebra-gradientes-image-container {
  height: calc(100vh / 2);
  width: 100%;
}

.quiebra-gradientes-image-container img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.quiebra-gradientes-info {
  height: 100%;
}

/* HOME SERVICES */
.services-home {
  width: 100%;
  height: 100%;
}

.services-home .volcano-img-container {
  max-height: 100%;
  min-width: 100%;
  height: 100%;
  width: 100%;
  background-image: url("./assets/arenalVolcano.jpg");
  background-size: cover;
  background-attachment: fixed;
  filter: opacity(90%);
}

.services-home .volcano-img-container .card {
  height: 80vh;
}

/* DOCUMENTATION */
.documentation-lake-bg {
  max-height: 100%;
  min-width: 100%;
  height: 100%;
  width: 100%;
  background-image: url("./assets/documentation-img-1.jpg");
  background-size: cover;
  background-attachment: fixed;
}

/* HOME PROJECTS  */
.home-projects div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 70vh;
}

.home-projects div h1 {
  font-size: 60px;
  background: #eee;
  padding: 8px 24px;
}

/* .home-projects .projects-info {
  background-color: var(--asada-lemonade-green);
} */

.home-projects div:nth-child(3) {
  height: 400px;
  background-size: cover;
  background-attachment: fixed;
}

.home-projects div:nth-child(4) {
  background-color: aquamarine;
}

/* HOME PHOTO GALLERY */
.photo-gallery {
  width: 100%;
  height: 100%;
}

.photo-gallery .btn-container {
  display: grid;
  place-items: center;
}

.photo-gallery div {
  max-height: 100%;
  height: 100%;
  width: 100%;
}

.photo-gallery .portfolio-box {
  position: relative;
  width: 100%;
  max-height: 18.5rem;
  overflow: hidden;
  margin-bottom: 1.2rem;
  border-top: 0.5rem solid #c8d5e0;
}
.photo-gallery .portfolio-box img {
  width: 100%;
  height: 100%;
}

.photo-gallery .portfolio-box .portfolio-info {
  /*   background-color: rgba(121, 185, 214, 0.75); */
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
}
.photo-gallery .portfolio-box .portfolio-info .caption {
  display: flex;
  justify-content: center;
  text-align: center;
  color: #fff;
  position: absolute;
  top: 45%;
}

.photo-gallery .portfolio-box .portfolio-info .caption h4 {
  text-transform: uppercase;
  display: block;
}

.portfolio-box:hover img {
  transform: scale(1.5);
  transition-duration: 1000ms;
  transition-property: all;
}

.portfolio-box:hover .portfolio-info {
  opacity: 1;
  visibility: visible;
  transition-duration: 1000ms;
  transition-property: all;
}

/* ABOUT US */
/* HERO SECTION */
.about-us-hero {
  height: 100%;
}

.hero-image {
  width: 100%;
  height: 100%;
}

.hero-image img {
  width: 100%;
  height: 100%;
}
/* MISSION & VISION */
/* .about-us-mv {
  height: 100vh;
} */
/* JUNTA DIRECTIVA */
.about-us-junta-directiva .junta-directiva-members .card {
  display: grid;
  grid-template-columns: 2fr 2fr;
  max-width: 390px;
  width: 100%;
  min-height: 320px;
}

.about-us-junta-directiva .junta-directiva-members .card figure {
  height: 100%;
  width: 100%;
}

.about-us-junta-directiva .junta-directiva-members .card figure img {
  height: 100%;
  width: 100%;
}

/* INFORMES */
.about-us-informes {
  height: 100%;
}

.about-us-informes .container {
  height: 100%;
}

/* .about-us-informes .about-us-informes-list {
  display: flex;
  justify-content: end;
} */

.about-us-informes .about-us-informes-list .card {
  margin: 0.5rem 0;
  width: 100%;
  max-width: 600px;
}

/* SITE MAP */

.about-us_site-map .modal {
  width: 100%;
  overflow: auto;
}
.about-us_site-map .modal .modal-box {
  position: relative;
  height: 100%;
  width: 100%;
  max-width: 720px;
}
.about-us_site-map .modal .modal-box img {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  animation-name: zoom;
  animation-duration: 0.6s;
  object-fit: contain;
  z-index: -1;
}

/* PHOTO GALLERY */
.photo-gallery {
  height: 100%;
  width: 100%;
}

.photo-gallery .images-wrapper .img-item {
  position: relative;
  max-height: 320px;
  width: 100%;
}

.photo-gallery .images-wrapper .img-item img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.photo-gallery .images-wrapper .img-item .overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #008cba;
  overflow: hidden;
  opacity: 0.7;
  width: 100%;
  height: 100%;
  transform: scale(0);
  transition: 0.3s ease;
}

.photo-gallery .images-wrapper .img-item:hover .overlay {
  transform: scale(1);
}

.photo-gallery .images-wrapper .img-item .overlay .text {
  color: white;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
}

/* POSTS */

.posts-section {
  height: 100%;
  background-image: url("./assets/river.jpg");
  background-position: center;
  background-attachment: fixed;
}

.posts-section .bg {
  width: 100%;
  height: 100%;
  background-color: rgba(23, 71, 118, 0.9);
}

.posts-section .post-item {
  height: 100%;
  width: 1000px;
  margin: 2rem auto;
}

/* PROJECTS CLIENT */
.projects-section {
  height: 100%;
  background-image: url("./assets/rio.jpeg");
  background-position: center;
  background-attachment: fixed;
}

.projects-section .bg {
  width: 100%;
  height: 100%;
  background-color: rgba(23, 71, 118, 0.9);
}

.projects-section .project-item {
  height: 100%;
  width: 1000px;
  margin: 2rem auto;
}

/* SERVICES */
.services-bg {
  background-color: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100%;
  z-index: 10;
  background-repeat: no-repeat;
}

.modal-info-display {
  grid-template-columns: 1fr 2fr;
}
.suggestion-info {
  max-width: 80%;
  grid-template-columns: 1fr 2fr;
}

.carousel-item.activeSlide {
  opacity: 1;
  transform: translateX(0);
}

.carousel-item.lastSlide {
  transform: translateX(-100%);
}
.carousel-item.nextSlide {
  transform: translateX(100%);
}

/* REACT SLIDER */
/* .slick-arrow.slick-prev,
.slick-arrow.slick-next {
  background-color: #333c4d;
  border-radius: 50%;
} */

/* ANIMATIONS */
.anim-opacity-fade-in {
  animation: opacityFadeIn 1s linear forwards;
}

@keyframes opacityFadeIn {
  0% {
    transform: translateY(-50%);
    opacity: 0;
  }
  1000% {
    transform: translateY(50%);
    opacity: 1;
  }
}

@-webkit-keyframes zoom {
  from {
    -webkit-transform: scale(0);
  }
  to {
    -webkit-transform: scale(1);
  }
}

@keyframes zoom {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}
