@keyframes ldio-ukf4q3mvfan {
  0% {
    top: 60.76px;
    left: 60.76px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 11.78px;
    left: 11.78px;
    width: 97.96px;
    height: 97.96px;
    opacity: 0;
  }
}
.ldio-ukf4q3mvfan div {
  position: absolute;
  border-width: 1.24px;
  border-style: solid;
  opacity: 1;
  border-radius: 50%;
  animation: ldio-ukf4q3mvfan 2.127659574468085s cubic-bezier(0, 0.2, 0.8, 1)
    infinite;
}
.ldio-ukf4q3mvfan div:nth-child(1) {
  border-color: #2fb5a7;
  animation-delay: 0s;
  border-width: 2px;
}
.ldio-ukf4q3mvfan div:nth-child(2) {
  border-color: #28292f;
  animation-delay: -1.0638297872340425s;
  border-width: 2px;
}
.loadingio-spinner-ripple-hcx7mfygf2l {
  width: 124px;
  height: 124px;
  display: flex;
  justify-content: center;
  overflow: hidden;
  background: none;
}
.ldio-ukf4q3mvfan {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-ukf4q3mvfan div {
  box-sizing: content-box;
}
